<template>
	<div class="firma">
		<button @click="clickvisiblefirma()" class="buttonfirma"><v-icon class='icon' style="">create</v-icon>{{text}}</button>
		<div v-show="visiblefirma" class="firmacontent">
			<div class="contentfirma">
				<div class="title">&nbsp;&nbsp;&nbsp;Firma<button @click="clickvisiblefirma()"> <v-icon class='icon' style="">clear</v-icon></button></div>
				 <v-text-field
                    v-model="nombrefirmante"
                    align-left
                    type="text"
                    label="Nombre del firmante"
					class="ml-3 mr-3"
                  ></v-text-field>

				<canvas id="firmaCanvas" :width="`${this.width-20}px`" :height="`${this.height-160}px`"></canvas><!-- 110 * 35 --><!-- 440 * 140 -->
				
				<div class="bottom">
					<button @click="clearArea()"><v-icon class=''>delete</v-icon> Limpiar</button>
					<button @click="firmaOk()"><v-icon class=''>check</v-icon> Ok</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import Auth from '@/mixins/auth.mixin'
//import EventBus from '@/events/index'
//import EVT from '@/events/eventsnames'
//import {from} from 'rxjs'
//import Tools from '@/services/tools.service'

// const _user = new UsersDbService()
 import { gw } from '@/gw.js'

export default {
    mixins: [gw],
	name: 'Firma',
	props: ["text"],
    //el: '#draw',
    data: () => ({
		nombrefirmante: '',
		mousePressed: false,
		visiblefirma:false,
		lastX:0,
		lastY:0,
		c:null,
		ctx:null,
		width:0,
		height:0
	}),
	computed:{
		getwidth:function(){
			return window.innerWidth;
		},
		getheight:function(){
			return window.innerHeight;
		}
	},
	mounted (){		 
		this.printcanvas();
		  window.addEventListener("resize", this.printcanvas);
	},
	destroyed() {
		window.removeEventListener("resize", this.printcanvas);
	},
	methods: {
		printcanvas(){
			this.width = window.innerWidth;
			this.height = window.innerHeight;

			if(this.width>800){this.width=800;}
			if(this.height>600){this.height=600;}


			this.c = document.getElementById('firmaCanvas');
			this.ctx = this.c.getContext('2d');

			this.c.addEventListener('mousedown', (e)=>{
				this.mousePressed = true;
				this.Draw(e.clientX-this.c.offsetLeft, e.clientY-this.c.offsetTop, false);
			});
			
			this.c.addEventListener('mouseup', ()=>{
				this.mousePressed = false;
			});
			
			this.c.addEventListener('mouseleave', ()=>{
				this.mousePressed = false;
			});
			
			this.c.addEventListener('mousemove', (e)=>{
				if (this.mousePressed) {
					this.Draw(e.clientX-this.c.offsetLeft, e.clientY-this.c.offsetTop, true);
				}
			});


			this.c.addEventListener('touchstart', (e)=>{
				this.lastX=e.touches[0].clientX-this.c.offsetLeft;
				this.lastY=e.touches[0].clientY-this.c.offsetTop;
			});
			
			this.c.addEventListener('touchmove', (e)=>{
				this.Draw(e.touches[0].clientX-this.c.offsetLeft, e.touches[0].clientY-this.c.offsetTop, true);
			});
		},

		Draw(x, y, isDown) {
			if (isDown) {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'black';
				this.ctx.lineWidth = 1;
				this.ctx.lineJoin = "round";
				this.ctx.moveTo(this.lastX, this.lastY);
				this.ctx.lineTo(x, y);
				this.ctx.closePath();
				this.ctx.stroke();
			}
			this.lastX = x; this.lastY = y;
		},

		clearArea() {
			// Use the identity matrix while clearing the canvas
			this.ctx.setTransform(1, 0, 0, 1, 0, 0);
			this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
		},
		clickvisiblefirma(){
			this.clearArea();
			this.visiblefirma = !this.visiblefirma;

			if(this.visiblefirma){
				document.getElementById("app").style.height = "0vh";
				document.getElementById("app").style.overflowY = "hidden"
			}
			else{
				document.getElementById("app").style.height = "initial";
				document.getElementById("app").style.overflowY = "auto"
			}
		},
		firmaOk(){
			var dataURL = this.c.toDataURL();
			console.log(dataURL);
			this.clickvisiblefirma();

			var data = {};
			data.dataURL = dataURL;
			data.nombrefirmante = this.nombrefirmante;
			this.setFirma(data);
		},
		/*resizeImage(base64Str) {//No funciona..
			var img = new Image();
			img.src = base64Str;
			var canvas = document.createElement('canvas');
			var MAX_WIDTH = 220;
			var MAX_HEIGHT = 70;
			var width = img.width;
			var height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
				height *= MAX_WIDTH / width;
				width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
				width *= MAX_HEIGHT / height;
				height = MAX_HEIGHT;
				}
			}
			canvas.width = width;
			canvas.height = height;
			var ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0, width, height);
			return canvas.toDataURL('image/png', 0.1);
		}*/
	}
}

</script>