<template>
  <div class="addemail">
    <v-card class="mx-auto elevation-0">
      <v-container class="py-0 v-card v-sheet theme--light">
        <v-chip-group class="mt-3"
          column
          multiple
          >
          <v-chip
            v-for="chip in objects"
            v-bind:class="{ 'v-chip-active': chip.selected }"
            :key="chip.text"
            @click="check(chip)"
          >
            <v-icon v-if="chip.selected" class=''>check</v-icon>
            {{ chip.text }}
            <!-- <v-icon rifght v-text="'close'" @click.prevent.stop="uncheck(chip)"></v-icon> -->
          </v-chip>
        </v-chip-group>

        <!-- <v-row align="center" justify="start">
          <v-col v-if="!allSelected" cols="12">
            <v-text-field
              ref="search"
              v-model="search"
              full-width
              hide-details
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
        </v-row> -->

        <div class="group-buttons">
            <v-btn v-if="selectall_state" elevation="2" small @click="selectall()"><v-icon class=''>check</v-icon> Seleccionar todos</v-btn>
            <v-btn v-if="!selectall_state" elevation="2" small @click="selectall()"><v-icon class=''>close</v-icon> Deseleccionar todos</v-btn>
            <v-btn elevation="2" small @click="addItem()"><v-icon class=''>add</v-icon> Añadir Email</v-btn>
        </div>

      </v-container>

      <!-- <v-divider v-if="!allSelected"></v-divider> -->

      <!-- <v-list>
        <v-list-item @click="addItem()">
          <v-list-item-title>Añadir</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="item in searchedItems()"
          :key="item.text"
          :disabled="loading"
          @click="check(item)"
        >
          <v-list-item-avatar v-if="item.icon">
            <v-icon :disabled="loading" v-text="item.icon"></v-icon>
          </v-list-item-avatar>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list> -->

      <!-- <v-divider></v-divider> -->

     <!--  <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="purple" text @click="done"> Ok </v-btn>
      </v-card-actions> -->
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Nuevo email</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-bind:class="{ 'emailinvalid': !emailvalid }"
                  v-model="newItem"
                  label="Email"
                  required
                ></v-text-field>
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resoveDialog(null)">
            Cancelar
          </v-btn>
          <v-btn :disabled="!emailvalid" color="blue darken-1" text @click="resoveDialog(newItem)">
            Añadir Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @vue-prop {Function} onDone - Funcion que se ejecuta cuando se ha finalizado la modificación de la lista, envia la lista de los seleccionados como parametro.
 * @vue-prop {Array} items - Items a mostrar en la lista
 *
 * Formato de item:
 * <pre>
 * {
 *    text: "ingenieria@tagingenieros.com",
 *    icon: "mdi-glass-wine",
 *    selected: false,
 * }
 * </pre>
 *
 */
import { gw } from "@/gw.js";


export default {
  mixins: [gw],
  name: 'AddEmail',
  props: {
    objects: { type: Array },
    onDone: { type: Function }
  },
  data: () => ({
    //loading: false,
    //search: '',

    newItem: '',
    dialog: false,
    resoveDialog: null,
    selectall_state: true,
    emailvalid:false
  }),
  mounted() {
    if(this.allSelected()){
      this.selectall_state = false;
    }
    else{
      this.selectall_state = true;
    }
  },
  watch:{
    newItem(val){
      if(this.validarEmail(val)){
        this.emailvalid=true;
      }
      else{
        this.emailvalid=false;
      }
    }
  },
  methods: {
    /**
     * Devuelve los items filtrados por el campo de busqueda
     * @returns Boolean
     */
    /*searchedItems() {
      const search = this.search.toLowerCase()
      if (!search) return (this.objects || []).filter(item => !item.selected)

      return (this.objects || []).filter(item => {
        const text = item.text.toLowerCase()
        return !item.selected && text.indexOf(search) > -1
      })
    },*/
    /**
     * Devuelve si estan todos los items seleccionados
     * @returns Boolean
     */
    allSelected() {
      return !(this.objects || []).map(item => item.selected).includes(false)
    }, 

    selectall(){
      if(this.selectall_state){
        this.selectall_state = false;
        this.objects.forEach(element => {
          element.selected = true;
        });
      }
      else{
        this.selectall_state = true;
        this.objects.forEach(element => {
          element.selected = false;
        });
      }
      
    },

    /**
     * Devuelve los seleccionados
     * @returns Array
     */
    /*selections() {
      return (this.objects || []).filter(item => item.selected)
    },*/
    /**
     * Marca como seleccionado un item
     * @param {object} item - item a modificar
     */
    check(item) {
      item.selected = !item.selected
      // Esta version no distingue entre computed y methodos? Cual es cual? 
      // Porque las galletas oreo estan tan buenas?
      this.newItem = item.text
      this.newItem = ""

      if(this.allSelected()){
        this.selectall_state = false;
      }
      else{
        this.selectall_state = true;
      }
    },
    /**
     * Marca como no seleccionado un item
     * @param {object} item - item a modificar
     */
    /*uncheck(item) {
      item.selected = false
      // try {
      //   this.objects.find(i => i.text == item.text).selected = false
      // } catch(e) {}
      this.newItem = item.text
      this.newItem = ""
    },*/
    /**
     * Añade o modifica un item a la lista, por defecto seleccionado
     * @param {object} item - item a modificar
     */
    async addItem(item) {
      // if (item && item.editable != true) return;

      this.dialog = true

      if (item) this.newItem = item.text

      const text = await new Promise(resolve => {
        this.resoveDialog = resolve
      })

      if (text) {
        if (item) {
          item.text = text
        } else {
          const newItem = { text, selected: true, editable: true }
          this.objects.push(newItem)
          // this.objects = [...this.objects, newItem]
        }
      }

      this.newItem = ''
      this.search = ''
      this.dialog = false
    },
    /**
     * Envia al componente padre la lista de los modificados en caso de que el prop onDone este seteado.
     */
    done() {
      if (this.onDone) this.onDone(this.objects)
    }
  }
}
</script>